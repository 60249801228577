
















































































import { Component, Vue, Watch } from "vue-property-decorator"
import topNav from "@/components/topNav.vue"
import goodsList from "./components/goodsList/goodsList.vue"
import MescrollVue from "mescroll.js/mescroll.vue"

@Component({
	components: {
		topNav,
		goodsList,
		MescrollVue
	}
})
export default class Default extends Vue {
	updateOrIntegral: Number = 0
	isUpdate: boolean = true
	isIntegral: boolean = true
	isUpdateRotate: boolean = false
	isIntegralRotate: boolean = false
	topNavTitle: String = ""
	goodsListData: Array<any> = []
	goodsListParams: any = {
		CategoryId: this.$route.query.categoryId,
		SortType: 0,
		Asc: 0
	}
	mescrollDown: any = {
		callback: this.mescrollData
	}
	mescrollUp: any = {
		isBounce: false,
		use: false
	}
	mescroll: any = null

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	created() {
		this.topNavTitle = this.$route.query.name.toString()
		this.getGoodsData()
	}

	mescrollInit(mescroll: any) {
		this.mescroll = mescroll // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
	}

	beforeDestroy() {
		this.mescroll.setBounce(true)
	}

	async mescrollData(mescroll: any) {
		await this.getGoodsData()
		this.$nextTick(() => {
			mescroll.endSuccess()
		})
	}

	getGoodsData() {
		this.$api.goodsList(this.goodsListParams).then((res: any) => {
			console.log(res)
			this.goodsListData = res.data
		})
	}

	//默认排序
	async defalutRank() {
		this.goodsListParams = {
			CategoryId: this.$route.query.categoryId,
			SortType: 0,
			Asc: 0
		}
		this.updateOrIntegral = 0
		await this.getGoodsData()
	}

	// 时间顺序
	async changeUpdateRank() {
		if (this.updateOrIntegral !== 1) {
			this.updateOrIntegral = 1
			this.isUpdate = false
			this.isIntegral = true
			if (this.isUpdateRotate == false) {
				this.goodsListParams.Asc = 0
			} else {
				this.goodsListParams.Asc = 1
			}
			this.goodsListParams.SortType = 1
		} else {
			this.isUpdateRotate = !this.isUpdateRotate
			this.goodsListParams.SortType = 1
			if (this.goodsListParams.Asc == 0) {
				this.goodsListParams.Asc = 1
			} else {
				this.goodsListParams.Asc = 0
			}
		}
		await this.getGoodsData()
	}

	// 积分顺序
	async changeIntegralRank() {
		if (this.updateOrIntegral !== 2) {
			this.updateOrIntegral = 2
			this.isUpdate = true
			this.isIntegral = false
			if (this.isIntegralRotate == false) {
				this.goodsListParams.Asc = 0
			} else {
				this.goodsListParams.Asc = 1
			}
			this.goodsListParams.SortType = 2
		} else {
			this.isIntegralRotate = !this.isIntegralRotate
			this.goodsListParams.SortType = 2
			if (this.goodsListParams.Asc == 0) {
				this.goodsListParams.Asc = 1
			} else {
				this.goodsListParams.Asc = 0
			}
		}
		await this.getGoodsData()
	}
}
