import { render, staticRenderFns } from "./goodsClassifyList.vue?vue&type=template&id=1a91fdad&scoped=true&"
import script from "./goodsClassifyList.vue?vue&type=script&lang=ts&"
export * from "./goodsClassifyList.vue?vue&type=script&lang=ts&"
import style0 from "./goodsClassifyList.vue?vue&type=style&index=0&id=1a91fdad&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a91fdad",
  null
  
)

export default component.exports